import styled from 'styled-components';

import { BaseButton } from './BaseButton';

export const TertiaryButton = styled(BaseButton)`
  border: 1px solid ${(props) => props.theme.colors.grey.dark};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grey.dark};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.grey.dark};
  }
  &:disabled {
    pointer-events: none;
    background-color: #e7eaf0;
    color: #747986;
  }

  .loading-spinner {
    path {
      fill: ${(props) => props.theme.colors.grey.dark};
    }
  }
`;
